import { IConstantsThis, IKernelFunctionThis } from "gpu.js";
import gpu from "./gpu";

export const makeBriExtractor = (imageShape: [number, number]) =>
  gpu
    .createKernel(function (img: number[][][]) {
      const pixel = img[this.thread.y][this.thread.x];

      const r = pixel[0];
      const g = pixel[1];
      const b = pixel[2];

      const val = (r + g + b) / 3.0;
      return val;
    })
    .setOutput(imageShape);

interface FactorConstants extends IConstantsThis {
  factor: number;
  inX: number;
  inY: number;
}
interface FactorKernelThis extends IKernelFunctionThis {
  constants: FactorConstants;
}

export const makeDownsampler = (inShape: [number, number], factor: number) => {
  const outShape = [
    Math.floor(inShape[0] / factor),
    Math.floor(inShape[1] / factor),
  ];

  return gpu
    .createKernel(function (this: FactorKernelThis, img: number[][]) {
      const startX = this.constants.factor * this.thread.x;
      const startY = this.constants.factor * this.thread.y;

      // calculate average over pixels
      let sum = 0.0;
      for (let j = startY; j < startY + this.constants.factor; j++) {
        for (let i = startX; i < startX + this.constants.factor; i++) {
          if (i < this.constants.inX) {
            if (j < this.constants.inY) {
              sum = sum + img[j][i];
            }
          }
        }
      }
      return sum / this.constants.factor / this.constants.factor;
    })
    .setOutput(outShape)
    .setConstants({ factor, inX: inShape[0], inY: inShape[1] });
};

export const makeUpsampler = (outShape: [number, number], factor: number) =>
  gpu
    .createKernel(
      function (this: FactorKernelThis, img: number[][]) {
        const x = Math.floor(this.thread.x / this.constants.factor);
        const y = Math.floor(this.thread.y / this.constants.factor);
        return img[y][x];
        // const x = 0;
        // const y = 0;
        // return img[0][0];
      },
      { constants: { factor } }
    )
    .setOutput(outShape);
