import React, { FunctionComponent } from "react";
import { Form } from "react-bootstrap";

type Props = {
  samplingFactor: number;
  setSamplingFactor: React.Dispatch<React.SetStateAction<number>>;
  className?: string;
};

const SamplingButton: FunctionComponent<Props> = ({
  samplingFactor,
  setSamplingFactor,
  className = "",
}) => {
  return (
    <Form.Group className={className}>
      <Form.Label className="mb-0">Sampling Factor</Form.Label>
      <Form.Control
        as="select"
        className="mt-1 mr-sm-2"
        value={samplingFactor}
        onChange={(event) => {
          setSamplingFactor(parseInt(event.target.value));
        }}
      >
        <option value={2}>2x</option>
        <option value={4}>4x</option>
        <option value={8}>8x</option>
        <option value={16}>16x</option>
      </Form.Control>
    </Form.Group>
  );
};

export default SamplingButton;
