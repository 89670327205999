import React, {
  CSSProperties,
  FunctionComponent,
  useRef,
  useState,
} from "react";
import "../styles/Draggable.css";
import { NumberTuple } from "../types";

const getClientPos = (event: MouseEvent | TouchEvent) => {
  // ectraxts clientX / clientY from both mouse & touch events
  if (event instanceof MouseEvent) {
    return [event.clientX, event.clientY];
  } else {
    // if (event instanceof TouchEvent)
    var touch = event.touches[0];
    return [touch.clientX, touch.clientY];
  }
};

const Draggable: FunctionComponent = ({ children }) => {
  const [offset, setOffset] = useState<NumberTuple>([0, 0]);
  const ref = useRef<HTMLDivElement>(null);

  const startDrag: React.MouseEventHandler<HTMLDivElement> &
    React.TouchEventHandler<HTMLDivElement> = (event: {
    nativeEvent: MouseEvent | TouchEvent;
  }) => {
    const el = ref?.current;
    if (!el) return;

    const nativeEvent = event.nativeEvent;
    nativeEvent.preventDefault();

    // create (closure) event listeners for drag
    let [prevX, prevY] = getClientPos(nativeEvent);
    let _offset = offset; // local copy to avoid timing issues with setState

    const moveFunc = (e: MouseEvent | TouchEvent) => {
      // update offset
      const [clientX, clientY] = getClientPos(e);
      let newX = prevX - clientX;
      let newY = prevY - clientY;

      _offset = [_offset[0] - newX, _offset[1] - newY];

      setOffset(_offset);

      prevX = clientX;
      prevY = clientY;

      // stop all other action associated with this event
      e.stopPropagation();
      e.preventDefault();
    };
    window.addEventListener("mousemove", moveFunc);
    window.addEventListener("touchmove", moveFunc);

    const removeListeners = () => {
      window.removeEventListener("mousemove", moveFunc);
      window.removeEventListener("touchmove", moveFunc);

      window.removeEventListener("mouseup", removeListeners);
      window.removeEventListener("touchend", removeListeners);
      window.removeEventListener("touchcancel", removeListeners);
    };
    window.addEventListener("mouseup", removeListeners);
    window.addEventListener("touchend", removeListeners);
    window.addEventListener("touchcancel", removeListeners);
  };

  const style: CSSProperties = {
    position: "inherit",
    left: offset[0],
    top: offset[1],
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        style={style}
        onMouseDown={startDrag}
        onTouchStart={startDrag}
        className="draggable"
        ref={ref}
      >
        {children}
      </div>
    </div>
  );
};

export default Draggable;
