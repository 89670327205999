import React, { FunctionComponent, useMemo, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import {
  makeImageEncoder,
  makeRandomGen,
  makeShapeEncoder,
} from "../gpu/generate_encoding";
import {
  makeBriExtractor,
  makeDownsampler,
  makeUpsampler,
} from "../gpu/helpers";
import { useOpacityMapToCanvas } from "../gpu/hooks";
import { NumberTuple } from "../types";
import Draggable from "./Draggable";

type Props = {
  img: HTMLImageElement;
  samplingFactor: number;
};

const SecretShares: FunctionComponent<Props> = ({ img, samplingFactor }) => {
  // canvas refs to control their content
  const enc1Ref = useRef<HTMLCanvasElement>(null);
  const enc2Ref = useRef<HTMLCanvasElement>(null);

  const hasImage = img.width > 0 && img.height > 0;
  const imgShape: [number, number] = useMemo(() => [img.width, img.height], [
    img,
  ]);

  const kernelToEnc1 = useOpacityMapToCanvas(imgShape, enc1Ref);
  const kernelToEnc2 = useOpacityMapToCanvas(imgShape, enc2Ref);

  // extract brightness from input image
  //   const imgBriFull = useMemo(() => {
  //     if (!hasImage) return [];

  //     console.log(10);
  //     const extractBri = makeBriExtractor(imgShape); //.setPipeline(true);
  //     console.log(11);
  //     return extractBri(img);
  //     console.log(12);
  //   }, [hasImage, imgShape, img]);

  // generate pair of images
  const [enc1, enc2] = useMemo(() => {
    if (!hasImage) return [[], []];

    const extractBri = makeBriExtractor(imgShape); //.setPipeline(true);
    const imgBriFull = extractBri(img);

    const downsampledSize: NumberTuple = [
      Math.floor(imgShape[0] / samplingFactor),
      Math.floor(imgShape[1] / samplingFactor),
    ];
    const downsample = makeDownsampler(imgShape, samplingFactor); //.setPipeline(true);
    const imgBri = downsample(imgBriFull);

    const generator = makeRandomGen(downsampledSize); //.setPipeline(true);
    const rand1 = generator();

    const encodeImg = makeImageEncoder(downsampledSize); //.setPipeline(true);
    const rand2 = encodeImg(imgBri, rand1);

    const encodeAsShapes = makeShapeEncoder(downsampledSize); //.setPipeline(true);
    const upsample = makeUpsampler(imgShape, samplingFactor / 2);
    const enc1 = upsample(encodeAsShapes(rand1));
    const enc2 = upsample(encodeAsShapes(rand2));

    return [enc1, enc2];
  }, [img, hasImage, imgShape, samplingFactor]);

  if (hasImage) {
    // draw img to canvas
    if (enc1Ref.current && enc2Ref.current) {
      kernelToEnc1(enc1);
      kernelToEnc2(enc2);
    }
  }

  return (
    <Row className={`canvas-container ${hasImage ? "my-3" : ""}`}>
      <Col md="12" lg="6">
        <canvas
          className="img-fluid"
          ref={enc1Ref}
          style={{ display: hasImage ? "unset" : "none" }}
        />
      </Col>

      <Col>
        <Draggable>
          <canvas
            className="img-fluid"
            ref={enc2Ref}
            style={{ display: hasImage ? "unset" : "none" }}
          />
        </Draggable>
      </Col>
    </Row>
  );
};
export default SecretShares;
