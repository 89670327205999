import React, { useState } from "react";
import "./App.css";
import { Container, Row, Col, Figure } from "react-bootstrap";

import SecretShares from "./components/SecretShares";
import ImageUploader from "./components/ImageUploader";
import SamplingButton from "./components/SamplingButton";

// ToDo:
// - bug that leads to recomputations of images
// - create README.md
// - add funtion to download images
// - add slider to choose brightness threshhold

// function downloadCanvas(canvas: HTMLCanvasElement, filename: string) {
//   var link = document.createElement("a");
//   link.download = filename + ".png";
//   link.href = canvas.toDataURL("image/png");
//   link.click();
// }

function App() {
  console.log("app rerender");

  // handle image state
  const [img, setImg] = useState(new Image());
  const hasImage = img.width > 0 && img.height > 0;

  const [samplingFactor, setSamplingFactor] = useState(4);

  return (
    <Container className="mt-3">
      <Row>
        <Col sm="12" md="7" className="d-flex align-items-end">
          <h1>Visual Cryptography</h1>
        </Col>

        <Col
          className="d-flex justify-content-end align-items-end"
          style={{ minHeight: "70px" }}
        >
          {hasImage && (
            <SamplingButton
              samplingFactor={samplingFactor}
              setSamplingFactor={setSamplingFactor}
              className="mr-4 mb-0"
            />
          )}
          <ImageUploader
            text={img.width === 0 ? "Choose Image" : "Change Image"}
            setImage={setImg}
            iconSize="small"
          />
        </Col>
      </Row>

      {!hasImage && <p className="mt-2">Upload an image to see the effect!</p>}

      <SecretShares img={img} samplingFactor={samplingFactor} />

      {/* {hasImage && (
        <Row className="controls">
          <Col className="d-flex justify-content-between align-items-center">
            <Button
              variant="outline-success"
              onClick={() => {
                if (!enc1Ref.current || !enc2Ref.current) return;
                downloadCanvas(enc1Ref.current, "file1");
                downloadCanvas(enc2Ref.current, "file2");
              }}
            >
              Download shares
            </Button>
          </Col>
        </Row>
      )} */}

      <h4 className="mt-2">The method</h4>
      <Row className="description mb-5">
        <Col md="12" lg="6" xl="4">
          <p>
            Visual cryptography is a secure method for sharing a (visual) secret
            between two parties. An input image (the secret) is used to generate
            two images of white noise. When these two images are pixel-perfectly
            overlaid (e.g. by printing them on a plastic sheet, or dragging the
            image above on the right over the one on the left), the original
            image appears.
          </p>
          <p>
            For each pixel in the input image, the algorithm
            <span style={{ color: "#007139" }}> randomly chooses </span>
            one of two possible ways of encoding by 2x2 pixel patterns (see
            diagram on the right/below). The patterns are chosen such that they
            resemble the input pixel brightness when overlaid. If the choice
            happens truly randomly, the shares individually contain no (secret)
            information. However, the (pseudo) random number generator in this
            demo (which depends on your platform) might create patterns that
            could be exploited to extract some information from just one share.
          </p>

          <p>
            The scheme was originally described by{" "}
            <a
              href="https://link.springer.com/content/pdf/10.1007%2FBFb0053419.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Moni Naor and Adi Shamir
            </a>
            .
          </p>
        </Col>

        <Col>
          <Figure>
            <img
              src="/enc_diagram.jpeg"
              className="figure-img img-fluid rounded"
              alt="Pixel Encoding"
            />
            <figcaption className="figure-caption">
              Pixel patterns used in the image encoding
            </figcaption>
          </Figure>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
