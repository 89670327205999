import { GPU, IGPUSettings, KernelOutput } from "gpu.js";
import { useMemo } from "react";

export const useOpacityMapToCanvas = (
  imgShape: [number, number],
  canvasRef: React.RefObject<HTMLCanvasElement>
) => {
  const toCanvas = useMemo(() => {
    // disable premultiplied alpha - so that white pixels are transparent for overlaying
    let settings: IGPUSettings = {};
    if (canvasRef?.current) {
      const canvas = canvasRef?.current;
      const gl = canvas.getContext("webgl", { premultipliedAlpha: false });
      if (gl != null) settings = { canvas, context: gl };
    }

    const cvsGPU = new GPU({
      ...settings,
      mode: "gpu",
    });

    // create kernel
    return cvsGPU
      .createKernel(function (greyscale: number[][]) {
        const val = greyscale[this.thread.y][this.thread.x];
        this.color(0, 0, 0, 1 - val);
      })
      .setGraphical(true)
      .setOutput(imgShape);
  }, [imgShape, canvasRef]);

  const greyKernelToCanvas = useMemo(
    () => (kernelOut: KernelOutput) => {
      if (!canvasRef.current) {
        return;
      }

      if (imgShape[0] === 0 || imgShape[0] === 0) {
        return;
      }

      toCanvas(kernelOut);

      canvasRef.current.width = imgShape[0];
      canvasRef.current.height = imgShape[1];
    },
    [imgShape, canvasRef, toCanvas]
  );

  return greyKernelToCanvas;
};
