import React, {
  FunctionComponent,
  useEffect,
  useRef,
  useCallback,
} from "react";
import PublishIcon from "@material-ui/icons/Publish";

import "../styles/ImageUploader.css";

type Props = {
  setImage: React.Dispatch<React.SetStateAction<HTMLImageElement>>;
  style?: React.CSSProperties;
  text?: string;
  iconSize?: "small" | "large" | "inherit" | "default" | undefined;
};

const FileUploader: FunctionComponent<Props> = ({
  setImage,
  style = {},
  text = "",
  iconSize = "large",
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleImage = useCallback(
    (uploadEvent: any) => {
      const onUpload = (e: ProgressEvent<FileReader>) => {
        if (!(typeof e.target?.result === "string")) {
          return;
        }

        const uploadedImg = new Image();
        uploadedImg.addEventListener("load", () => {
          setImage(uploadedImg);
        });

        uploadedImg.src = e.target.result;
      };

      var reader = new FileReader();
      reader.onload = (reader) => onUpload(reader);
      uploadEvent.target.files.length &&
        reader.readAsDataURL(uploadEvent.target.files[0]);
    },
    [setImage]
  );

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }

    inputRef.current.addEventListener("change", handleImage, false);
  }, [handleImage]);

  return (
    // <span className="file-uploader">
    <div className="file-uploader">
      <input type="file" id="fileUploader" ref={inputRef} style={style} />
      <label htmlFor="fileUploader" className="button button-blue mb-0">
        {text && <span className="text">{text}</span>}
        <PublishIcon fontSize={iconSize} />
      </label>
    </div>
  );
};
export default FileUploader;
